const Blogs = [
    {
        heading: "Uncovering TOR's hidden gems: .onion URLs",
        blogId: "1",
        description: `
          CVE-2022-30334 is a vulnerability discovered in the Brave browser that allows for the leakage of ".onion" URLs. In this blog, we will cover how referrer and origin headers can leave a trail of breadcrumbs for anyone to follow, compromising the anonymity of the user.           
        `,
    }
]

export { Blogs };